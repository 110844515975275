<template>
  <v-select
    v-model="selected"
    :label="label"
    :reduce="reduce"
    :options="searchText ? localOptions : options.results"
    :get-option-label="getOptionLabel"
    :placeholder="placeholder"
    :multiple="multiple"
    @open="onOpen"
    @close="onClose"
    @search="
      (val, loading) => {
        loading(true);
        fetchOptions(val).then(() => loading(false));
      }
    "
    @input="onSelected"
  >
    <template #list-footer>
      <li
        v-show="options.next"
        ref="load"
        class="loader"
      >
        Loading more options...
      </li>
    </template>
  </v-select>
</template>

<script>
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    state: {
      type: [Object, Array],
      default: () => ([]),
    },
    autoFetch: {
      type: Boolean,
      default() {
        return true
      },
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {
          next: null,
          timer: null,
        }
      },
    },
    getOptionLabel: {
      type: Function,
      required: false,
      default: () => {},
    },
    reduce: {
      type: Function,
      required: false,
      default: v => v,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    lazyFetchData: {
      type: Function,
      required: false,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: false,
      default: 15,
    },
    search: {
      type: Function,
      required: false,
      default() {
        return new Promise(resolve => {
          setTimeout(() => resolve([]), 100)
        })
      },
    },
    value: {
      type: [Array, Object, String, Number],
      default: null,
    },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      observer: null,
      selectLoading: false,
      localOptions: [],
      searchText: '',
      selected: null,
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue
    },
  },
  mounted() {
    if (!this.options.results.length && this.autoFetch) {
      this.lazyFetchData({ ...this.filters, page_size: 15 })
    }
    this.observer = new IntersectionObserver(
      async ([{ isIntersecting, target }]) => {
        if (!isIntersecting || this.selectLoading) {
          return
        }
        if (!this.options.next) {
          return
        }
        this.selectLoading = true
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        await this.lazyFetchData({
          ...this.filters,
          page: this.options.next,
          page_size: this.pageSize,
        })
        this.selectLoading = false
        await this.$nextTick()
        ul.scrollTop = scrollTop
      },
    )
    this.selected = this.value
  },
  methods: {
    onOpen() {
      this.$nextTick(() => {
        if (this.$refs.load) {
          this.observer.observe(this.$refs.load)
        }
      })
    },
    onSelected(value) {
      // this.$emit("input", this.selected);
      this.$emit('input', value)
    },
    onClose() {
      this.observer.disconnect()
    },
    fetchOptions(val) {
      this.searchText = val
      if (this.timer) {
        clearTimeout(this.timer)
      }

      return new Promise(resolve => {
        // eslint-disable-next-line consistent-return
        this.timer = setTimeout(() => {
          if (!this.searchText) {
            return resolve()
          }
          this.lazyFetchData({ ...this.filters, search: val, page_size: 15 })
            .then(data => {
              this.localOptions = data.results
            })
            .finally(() => {
              resolve()
            })
        }, 500)
      })
    },
  },
}
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
