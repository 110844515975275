<template>
  <div>
    <b-row
      class="d-flex justify-content-between align-items-center mb-1 mb-lg-0"
    >
      <b-col
        :lg="importButton ? '12' : '10'"
      >
        <!-- Поиск: -->
        <b-row>
          <b-col
            v-if="search"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('general.search')"
              label-for="search"
            >
              <b-input-group
                :label="$t('general.search')"
                label-for="search"
              >
                <b-input-group-prepend
                  is-text
                  class="border-right-0"
                >
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="search"
                  v-model="filtered.search"
                  :placeholder="$t('general.search') + '...'"
                  class="border-left-0 pl-0"
                  @input="emitFiltered"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            v-if="fin"
            class="col-md-2"
          >
            <b-form-group
              label="По пользователям"
              label-for="fin"
            >
              <!--              <v-select-->
              <!--                  v-model="filtered.client"-->
              <!--                  class="bg-white rounded"-->
              <!--                  :get-option-label="el => {-->
              <!--                    return el.contact.full_name-->
              <!--                   }"-->
              <!--                  :reduce="opt => opt.contact.id"-->
              <!--                  :options="GET_CLIENTS_LIST.results"-->
              <!--                  @input="emitFiltered"-->
              <!--              />-->

              <app-select
                v-model="filtered.client"
                class="bg-white rounded"
                :get-option-label="
                  (item) => `${item.contact.id} ${item.contact.full_name}`
                "
                :options="GET_CLIENTS_LIST_FOR_FILTER"
                :filters="{ role__unique_name: 'student' }"
                :lazy-fetch-data="FETCH_CLIENTS_LIST_FOR_FILTER"
                :reduce="(opt) => opt.contact.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="fin"
            class="col-md-2"
          >
            <b-form-group
              label="С"
              label-for="birthdayEnd2"
            >
              <datepicker
                :value="filtered.from_date"
                :bootstrap-styling="true"
                :clear-button="true"
                :language="ru"
                :calendar-button="true"
                input-class="bg-white"
                format="yyyy-MM-dd"
                calendar-button-icon="fa fa-calendar"
                clear-button-icon="fa fa-times"
                @selected="dateSelect($event, 'from_date')"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="fin"
            class="col-md-2"
          >
            <b-form-group
              label="ПО"
              label-for="birthdayEnd2"
            >
              <datepicker
                :value="filtered.to_date"
                :bootstrap-styling="true"
                :clear-button="true"
                :language="ru"
                :calendar-button="true"
                input-class="bg-white"
                format="yyyy-MM-dd"
                calendar-button-icon="fa fa-calendar"
                clear-button-icon="fa fa-times"
                @selected="dateSelect($event, 'to_date')"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="level"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.level')"
              label-for="level"
            >
              <v-select
                id="level"
                v-model="filtered.level"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.level')"
                label="level"
                :options="GET_LEVELS_LIST"
                :reduce="(el) => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="gender"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.gender')"
              label-for="gender"
            >
              <v-select
                id="gender"
                v-model="filtered.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.gender')"
                label="title"
                :get-option-label="(e) => $t(e.title)"
                :options="genderOptions"
                :reduce="(el) => el.key"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="role"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.role')"
              label-for="role"
            >
              <v-select
                id="role"
                v-model="filtered.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.role')"
                label="title"
                :get-option-label="getLabel"
                :options="GET_USER_ROLES_LIST.results"
                :reduce="(el) => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="subject"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.subject')"
              label-for="subject"
            >
              <v-select
                id="subject"
                v-model="filtered.subjects"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.subject')"
                multiple
                :get-option-label="getLabel"
                :options="GET_SUBJECTS_LIST.results"
                :reduce="(el) => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="branchs"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.branchs')"
              label-for="group"
            >
              <v-select
                id="group"
                v-model="filtered.branchs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.branchs')"
                :get-option-label="el => el.title"
                :reduce="(el) => el.id"
                :options="GET_APPROVED_BRANCH.results"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="group"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.class')"
              label-for="class"
            >
              <v-select
                id="class"
                v-model="filtered.group"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.class')"
                label="title"
                :options="GET_CLASS_LIST.results"
                :reduce="(el) => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="tags"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('others.tags')"
              label-for="tags"
            >
              <v-select
                id="tags"
                v-model="filtered.tags"
                multiple
                :placeholder="$t('others.tags')"
                label="title"
                :options="
                  GET_GROUPS_LIST.results
                    ? GET_GROUPS_LIST.results
                    : GET_GROUPS_LIST
                "
                :reduce="(el) => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
          <!-- Дата рождения по -->
          <b-col
            v-if="yearChart"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('finance.year')"
              label-for="birthdayEnd"
            >
              <datepicker
                id="birthdayEnd"
                v-model="filtered.year"
                :bootstrap-styling="true"
                :clear-button="true"
                :language="ru"
                :calendar-button="true"
                :placeholder="$t('finance.year')"
                input-class="bg-white"
                minimum-view="year"
                format="yyyy"
                calendar-button-icon="fa fa-calendar"
                clear-button-icon="fa fa-times"
                @selected="emitFiltered"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- Добавить -->
      <b-col
        v-if="add"
        class="text-right mt-25 d-flex align-items-center justify-content-end"
        :class="importButton ? 'mb-2' : ''"
        :lg="importButton ? '12' : '2'"
      >
        <b-button
          v-if="importButton"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-2"
          @click="importData"
        >
          <feather-icon icon="ArrowDownIcon" />
          {{ $t("courses.import") }}
        </b-button>

        <b-button
          v-if="exportExcel"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-2 d-flex align-items-center"
          @click="onExportExcel"
        >
          <feather-icon icon="ArrowUpIcon" />
          {{ $t("courses.export") }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="addButtonEmit"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t("general.add") }}
          <!--Добавить button-->
        </b-button>
      </b-col>
      <slot />
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import appSelect from '@/components/AppSelect.vue'

export default {
  name: 'Filters',
  components: {
    vSelect,
    Datepicker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    appSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    fin: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: true,
    },
    level: {
      type: Boolean,
      default: false,
    },
    gender: {
      type: Boolean,
      default: false,
    },
    role: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: Boolean,
      default: false,
    },
    branchs: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Boolean,
      default: false,
    },
    yearChart: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Boolean,
      default: false,
    },
    groupOptions: {
      type: Array,
      default: () => [],
      required: false,
    },
    importButton: {
      type: Boolean,
      default: false,
    },
    exportExcel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filtered: {
        search: null,
        level: null,
        role: null,
        gender: null,
        subject: null,
        group: null,
        tags: null,
        year: null,
        branchs: null,

        client: null,
        from_date: null,
        to_date: null,
      },
      ru,
      genderOptions: [
        {
          key: 'male',
          title: 'users.male',
        },
        {
          key: 'female',
          title: 'users.female',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('finance', ['GET_CLIENTS_LIST_FOR_FILTER']),
    ...mapGetters('users', [
      'GET_LEVELS_LIST',
      'GET_USER_ROLES_LIST',
      'GET_USERS_LIST',
    ]),
    ...mapGetters('branches', ['GET_APPROVED_BRANCH']),
    ...mapGetters('classes', ['GET_CLASS_LIST']),
    ...mapGetters('knowledgeBase', ['GET_SUBJECTS_LIST', 'GET_GROUPS_LIST']),
  },
  mounted() {
    if (this.group) {
      this.FETCH_CLASS_LIST()
    }
    if (this.tags) {
      this.FETCH_GROUPS_LIST()
    }
    if (this.role) {
      this.FETCH_USER_ROLES_LIST()
    }
    if (this.subject) {
      this.FETCH_SUBJECTS_LIST()
    }
    if (this.branchs) {
      this.FETCH_APPROVED_BRANCH_LIST()
    }
    if (this.fin) {
      this.FETCH_USERS_LIST()
    }
  },
  methods: {
    ...mapActions('branches', ['FETCH_APPROVED_BRANCH_LIST']),
    ...mapActions('users', ['FETCH_USER_ROLES_LIST', 'FETCH_USERS_LIST']),
    ...mapActions('classes', ['FETCH_CLASS_LIST']),
    ...mapActions('knowledgeBase', [
      'FETCH_SUBJECTS_LIST',
      'FETCH_GROUPS_LIST',
    ]),
    ...mapActions('finance', ['FETCH_CLIENTS_LIST_FOR_FILTER']),

    getLabel(element) {
      if (element.title) return element.title[this.$i18n.locale]
      return element
    },
    emitFiltered() {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        if (!this.filtered.search) {
          this.$set(this.filtered, 'search', null)
        }
        if (!this.filtered.level) {
          this.$set(this.filtered, 'level', null)
        }
        if (!this.filtered.role) {
          this.$set(this.filtered, 'role', null)
        }
        if (!this.filtered.group) {
          this.$set(this.filtered, 'group', null)
        }
        if (!this.filtered.gender) {
          this.$set(this.filtered, 'gender', null)
        }
        if (!this.filtered.subjects) {
          this.$set(this.filtered, 'subjects', null)
        }
        if (!this.filtered.branchs) {
          this.$set(this.filtered, 'branchs', null)
        }
        if (!this.filtered.year) {
          this.$set(this.filtered, 'year', null)
        }
        if (!this.filtered.from_date) {
          this.$set(this.filtered, 'from_date', null)
        }
        if (!this.filtered.to_date) {
          this.$set(this.filtered, 'to_date', null)
        }
        if (!this.filtered.client) {
          this.$set(this.filtered, 'client', null)
        }
        if (!this.filtered.tags) {
          this.$set(this.filtered, 'tags', null)
        }
        this.$emit('filtered-items', this.filtered)
        console.log(this.filtered)
      }, 600)
    },
    importData() {
      this.$emit('import')
    },
    addButtonEmit() {
      this.$emit('add-button-clicked')
    },

    customFormatter(d) {
      console.log(new Date(d).toISOString().split('T')[0])
      return new Date(d).toISOString().split('T')[0]
    },
    onExportExcel() {
      this.$emit('onExportExcel', this.filtered)
    },
    dateSelect(d, type) {
      // eslint-disable-next-line prefer-destructuring
      this.filtered[type] = d ? new Date(d).toISOString().split('T')[0] : ''
      this.emitFiltered()
    },
  },
}
</script>

<style scoped></style>
